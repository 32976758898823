import React, { memo as Memo, useEffect, useRef } from 'react';
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin'

const ArrowRight = Memo(({ animate }) => {

    gsap.registerPlugin(MotionPathPlugin);

    //! Refs
    const arrRightRef = useRef();
    const pathRef = useRef();

    useEffect(() => {
        animate && (
            gsap.to(arrRightRef.current, {
                duration: 0.6,
                delay: 0.6,
                repeat: -1,
                repeatDelay: 0,
                yoyo: true,
                ease: "power1.inOut",
                motionPath: {
                    path: pathRef.current,
                    align: pathRef.current,
                    autoRotate: -80,
                    alignOrigin: [0.6, 0.6]
                }
            })
        );
    }, [animate])

    return (
        <svg className='svg-max' preserveAspectRatio="none" viewBox="0 0 177.29 228.41">
            <path ref={arrRightRef} d="M154.11,172.13l-2.94-7.44-19.38,7.64a116.13,116.13,0,0,0,6.83-43.39C136.84,78.63,102,44.73,73,25.16l-3.31-2.24L65.2,29.54l3.31,2.24c39.75,26.87,60.64,59.53,62.11,97.07a108.94,108.94,0,0,1-6.62,41.4L115.54,151l-7.33,3.22,13.6,31,6.21-2.73Z" />
            <path ref={pathRef} stroke="none" fill="none" d="M122.45,82.65a108.64,108.64,0,0,1,10.12,29,104.5,104.5,0,0,1,1.45,27" />
        </svg>
    )
})

export default ArrowRight;

import React, { memo as Memo, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap'

//* Style 
import style from './style.module.scss'

const Stroke = Memo(() => {
    //! Refs
    const contRef = useRef()
    const tl = useRef()

    //! States
    const [act, setAct] = useState(false)

    useEffect(() => {
        tl.current = gsap
            .timeline({
                paused: true
            })
            .to(contRef.current, 0.8, { strokeDashoffset: 0, ease: "power1.out" })
    }, [])

    useEffect(() => {
        act ? tl.current.reverse() : tl.current.play()
    }, [act, tl])

    return (
        <svg className={`${style.svg} strike-cont`} preserveAspectRatio="none" viewBox="0 0 1147.2 325.6" onMouseEnter={() => setAct(true)} onMouseLeave={() => setAct(false)}>
            <path ref={contRef} d="M693.6,322.1c0,0,455.5-81,450-217.2C1140,16,881.3-0.1,675.8,4.1C555.9,6.6,449.9,19.3,412.5,24.2
	        C354.5,32-36.5,85,6.8,222.4c25.2,80,301.6,99.9,486.2,89c250.3-14.7,267.1-50.3,271-55.5"/>
        </svg>

    )
})
export default Stroke;
import React, { memo as Memo, useMemo, useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'

//* Components
import Text from '@components/global/Text'
import StarSvg from '@components/global/SvgComponents/StarSvg'
import ArrowRight from '@components/global/SvgComponents/ArrowRight'
import ArrowLeft from '@components/global/SvgComponents/ArrowLeft'
import CustomLink from '@components/global/CustomLink'
import Stroke from '@components/global/SvgComponents/Stroke'

//* HOC's
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'

//* Style 
import style from './style.module.scss'

//* JSON's
import config from 'config.json'

const CustomerActions = Memo(({ data, resp, ...props }) => {
    //! States
    const [act, setAct] = useState(false)

    //! Ref's
    const ref = useRef()
    const addToRefs = useRef([])

    const tl = useRef();

    //! Animation
    useEffect(() => {
        tl.current = gsap
            .timeline({
                paused: true
            })
            .staggerTo(addToRefs.current, 0, { width: '0%' }, 0.30);
    }, [])

    useEffect(() => {
        act ? tl.current.play() : tl.current.reverse()
    }, [act, tl])

    //! First Element
    const firstElement = useMemo(() => (
        <CustomLink url={config.routes.default['contact-form'].path} className={style.firstElem}
            content={
                <div ref={ref} onMouseEnter={() => setAct(true)} onMouseLeave={() => setAct(false)}>
                    {
                        ['customerActionsWord1', 'customerActionsWord2'].map((item, i) => {
                            return (
                                <div className={style.firstElemItem} key={i}>
                                    <Text className={`${style.topTitle} h1 font-semi-bold font-poppins`} >
                                        {props.translate(item)}
                                        <span className={`${style.bottomline} bottomline `} ref={el => addToRefs.current[i] = el} />
                                        {i === 0 && (
                                            <span className={style.arrow1}>
                                                <ArrowLeft animate={resp} />
                                            </span>
                                        )}
                                    </Text>
                                </div>
                            )
                        })}
                </div>
            }
        />
    ), [resp, props.selectedLang])

    //! Middle Element
    const middleElement = useMemo(() => (
        <div className={style.midElem}>
            <div className={style.midBackImg} >
                <StarSvg text={props.translate('or')} animate={resp} />
                <div className={style.arrow2}>
                    <ArrowRight animate={resp} />
                </div>
            </div>
        </div>
    ), [resp, props.selectedLang])

    //! Last Element
    const lastElement = useMemo(() => (
        <div className={style.lastElem}>
            <div className={style.lastElemCont}>
                <CustomLink url={config.routes.default['team'].path}>
                    <Text className={`h2L font-light font-italic font-poppins`} >
                        {props.translate('joinOurTeam')}
                    </Text>
                    <div className={style.lastBackImg}>
                        <Stroke animate={resp} />
                    </div>
                </CustomLink>
            </div>
        </div>
    ), [resp, props.selectedLang])

    return (
        <div className={`${style.customerActions}  ${resp ? style.active : ''}`}>
            <div className={style.elemFlexWrap}>
                {firstElement}
                {middleElement}
                {lastElement}
            </div>
        </div>
    )
})

export default withLanguageContext(CustomerActions, ['selectedLang', 'translate'])
import React, { memo as Memo, useMemo, useState, useEffect, useRef } from 'react'

//* Hoc's
import { withRouter } from 'next/router'
import withDataContext from '@context/consumerHOC/DataConsumer'
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Config
import config from 'config.json'

//* Components
import CustomerActions from './CustomerActions'
import Text from '../Text'
import CustomLink from '../CustomLink'
import SocIcon from '../SocIcon'

const Footer = Memo(({ match, global, selectedLang, winWidth, winHeight, translate, ...props }) => {
    //! State
    const [isLight, setIsLight] = useState(true)
    const [footerPadding, setFooterPadding] = useState(0)

    //! Data
    const data = useMemo(() => global?.[selectedLang], [global, selectedLang])
    const routes = useMemo(() => config.routes.default, [config])

    //! Ref's
    const contRef = useRef()
    const footerMainRef = useRef()

    //! Detecting colors
    useEffect(() => {
        setIsLight(
            // props.router.pathname !== routes.home.path &&
            props.router.pathname !== routes.blog.path &&
            props.router.pathname !== routes.team.path &&
            props.router.pathname !== routes.singleBlog.path.replace(':slug', '[slug]')
        )
    }, [props.router.pathname])

    //! Getting year
    const year = useMemo(() => {
        const date = new Date()
        return date.getFullYear()
    }, [])

    //! Updating footer padding
    useEffect(() => {
        footerMainRef.current && setFooterPadding(footerMainRef.current.getBoundingClientRect().height)
    }, [winWidth, winHeight, props.router.pathname, data, props.footer, footerMainRef.current])

    return data && (
        <footer style={{ paddingBottom: `${footerPadding}px` }} className={` ${isLight ? '' : 'dark-container'}`} ref={contRef}>

            <div className='TickerAndCustomerActionsCont'>
                <div className='TickerAndCustomerActionsWrap'>
                    <CustomerActions data={data.customer_actions} resp={true} />
                </div>
            </div>

            <div ref={footerMainRef} className="footer-main">
                <div className="footer-top-cont">
                    <div className="addresses-cont">
                        <div className="addresses-row">

                            {data.contact_information.addresses.map((i, k) => (
                                <div key={k} className='address-item'>
                                    <a target="_blank" href={i.link} aria-label='noopener noreferer'>
                                        <Text text={i.city} className="fm-city p-l font-poppins font-semi-bold" />
                                        <Text text={i.address} className="fm-address p font-poppins font-regular" />
                                        <Text text={i.location} className="fm-location p font-poppins font-regular" />
                                    </a>
                                    <a className="fm-phone p-l font-anonymous font-bold" target="_blank" href={`tel:${i.phone.replace(/\s+/g, '')}`} aria-label='noopener noreferer'>
                                        {i.phone}
                                    </a>
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="menu-items-cont">
                        <div className="menu-items-row">
                            <div className="menu-col">
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes['about-us'].name)} url={routes['about-us'].path} />
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.services.name)} url={routes.services.path} />
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.portfolio.name)} url={routes.portfolio.path} />
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.contact.name)} url={routes.contact.path} />
                            </div>

                            <div className="menu-col">
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.team.name)} url={routes.team.path} />
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.awards.name)} url={routes.awards.path} />
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.blog.name)} url={routes.blog.path} />
                                <CustomLink className="menu-el p-m font-poppins font-regular" content={translate(routes.legal.name)} url={routes.legal.path} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-cont">
                    <div className="socials-cont">
                        <SocIcon className="footer-social-icons" isFooter type={2} menuIcon={true} isSocial={true} />
                    </div>

                    <Text text={`© ${year} Concept Studio.`} className="p font-anonymous font-regular" />
                </div>
            </div>
        </footer>

    )
})

export default withRouter(withUIContext(withLanguageContext(withDataContext(Footer, ['global']), ['selectedLang', 'translate']), ['winHeight', 'winWidth', 'lightHeader']))

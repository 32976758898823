import { memo as Memo, useMemo } from 'react';
import Head from 'next/head'
import { NextSeo } from 'next-seo'
import { withRouter } from 'next/router'

const CustomHead = Memo(({ meta, cfHead, router, ...props }) => {


    //! URL's
    const url = useMemo(() => process.env.host + `${router.locale !== 'en' ? `/${router.locale}` : ''}` + router.asPath, [router]);
    const urlEn = useMemo(() => process.env.host + router.asPath, [router]);
    const urlDe = useMemo(() => process.env.host + '/de' + router.asPath, [router]);

    //! No Index URL's
    const noIndexUrls = useMemo(() => (
        [
            "/de/portfolio/",
            "/de/legal/",
        ]
    ), [])

    //! Next Seo
    const seo = useMemo(() => {
        //? Global
        let locale = router.locale === 'de' ? 'de_DE' : 'en_US'

        //? For Admin
        let title = meta?.title
        let description = meta?.description
        let keywords = meta?.keywords
        let type = 'page'
        let images = [{ url: meta?.image?.src || process.env.host + '/images/default.jpeg', alt: meta?.image?.alt }]
        let canonical = router.locale === 'de' && noIndexUrls.includes(router.asPath) ? url.replace('/de', '') : url

        //? For Contentful
        if (cfHead) {
            title = cfHead.title
            description = cfHead.description
            keywords = cfHead.keywords
            type = 'article'
            images = [{ url: `${cfHead.ogimage.fields.file.url.replace(/\/\//, '')}}?w=1200&h=630`, alt: title }]
            canonical = (router.locale === 'de' && (router.asPath.includes('/blog/') || router.asPath.includes('/portfolio/'))) ? url.replace('/de', '') : url
        }

        return (
            <NextSeo
                title={title || 'Concept Studio'}
                description={description}
                canonical={canonical}
                type='website'
                additionalMetaTags={[
                    {
                        name: 'keywords',
                        content: `design agency ${keywords}`,
                    },
                ]}
                openGraph={{
                    url,
                    title,
                    description,
                    images,
                    locale,
                    type,
                    siteName: 'Concept Studio'

                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
                robotsProps={{
                    maxSnippet: -1,
                    maxImagePreview: 'large',
                    maxVideoPreview: -1,
                }}
                // noindex={router.locale === 'de' && cfHead}
                // nofollow={false}
                // nofollow={true}
                // noindex={true}
                noindex={router.locale === 'de' && !!cfHead}
                nofollow={false}
            />
        )
    }, [meta, cfHead, router])

    //! Schema Markup
    const schema = useMemo(() => {
        return props.schema || JSON.stringify(props?.cfHead?.schema)
    }, [props.schema, props?.cfHead])

    return (
        <>
            <Head>
                <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                <meta name="theme-color" content="#ffffff"></meta>
                {/* Apple */}
                <link rel="apple-touch-startup-image" href={`/180-180.jpg`} />
                <link rel="apple-touch-icon" href={`/180-180.jpg`} />
                <link rel="apple-touch-icon" sizes="152x152" href={`/152-152.jpg`} />
                <link rel="apple-touch-icon" sizes="128x128" href={`/128-128.jpg`} />
                <link rel="apple-touch-icon" sizes="180x180" href={`/180-180.jpg`} />
                <link rel="apple-touch-icon" sizes="167x167" href={`/167-167.jpg`} />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="Concept Studio" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                {/* Windows */}
                <meta name="application-name" content="Concept Studio" />
                <meta name="msapplication-TileColor" content=" #ffffff" />
                <meta name="msapplication-square70x70logo" content={`/70-70.jpg`} />
                <meta name="msapplication-square150x150logo" content={`/150-150.jpg`} />
                <meta name="msapplication-wide310x150logo" content={`/310-150.jpg`} />
                <meta name="msapplication-square310x310logo" content={`/310-310.jpg`} />
                {/* Android */}
                <link rel="icon" sizes="192x192" href={`/192-192.jpg`}></link>
                <link rel="shortcut icon" href={'/favicon.ico'} />
                <link rel="icon" type="image/x-icon" href={'/favicon.ico'} />

                {/* Alternate URL's */}
                <link rel="alternate" href={noIndexUrls.includes(router.asPath) ? url : urlDe} hrefLang="de" />
                <link rel="alternate" href={url} hrefLang="en" />
                <link rel="alternate" href={urlEn} hrefLang="x-default" />
                {schema && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />}
            </Head>

            {seo}
        </>
    )
})

export default withRouter(CustomHead)
import React, { forwardRef } from 'react';
//* Components
import Translate from '../Translate'

const Text = forwardRef((props, ref) => {
    let string = <Translate val={props.text} />

    switch (props.tag) {
        case 'h1':
            string = <h1 {...props.attr}  ref={ref} className={`comp_title ${props.className || 'h1'} ${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</h1>
            break;
        case 'h2':
            string = <h2 {...props.attr}  ref={ref} className={`comp_title ${props.className || 'h2'} ${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</h2>
            break;
        case 'h3':
            string = <h3 {...props.attr}  ref={ref} className={`comp_title ${props.className || 'h3'}${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</h3>
            break;
        case 'h4':
            string = <h4 {...props.attr}  ref={ref} className={`comp_title ${props.className || 'h4'} ${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</h4>
            break;
        case 'h5':
            string = <h5 {...props.attr}  ref={ref} className={`comp_title ${props.className || 'h5'}  ${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</h5>
            break;
        case 'h6':
            string = <h6 {...props.attr}  ref={ref} className={`comp_title ${props.className || 'h6'} ${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</h6>
            break;
        case 'label':
            string = <label {...props.attr}  ref={ref} className={`comp_title ${props.className || ''} ${props.uppercase ? 'text_upper' : ''}`} onClick={props.onClick || null}>{props.children || string}</label>
            break;
        case 'li':
            string = <li {...props.attr}  ref={ref} className={`comp_title ${props.className || ''} ${props.uppercase ? 'text_upper' : ''}`} onClick={props.onClick || null}>{props.children || string}</li>
            break;
        default:
            string = <p {...props.attr}  ref={ref} className={`comp_title ${props.className || 'p'} ${props.uppercase ? 'text_upper' : ''}`}>{props.children || string}</p>
    }

    return string
})

export default Text

import React, { memo as Memo } from 'react'

//* Component
import CustomHead from './CustomHead'
import Footer from '../Footer'

const Page = Memo(({ mainProps, meta, schema, ...props }) => {
    return (
        <>
            <CustomHead meta={meta} schema={schema} {...props?.head} />

            <main className={`page ${props.className || ''}`} {...mainProps}>
                {props.children}
            </main>

            {props.footer ? <Footer footer={props.footer} /> : <Footer />}

        </>
    )
})

export default Page